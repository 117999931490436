import mitt from 'mitt'

interface User {
  name: string
}

type ApplicationEvents = {
  // translates to: 
  // user:registered -> event name
  // User -> type as payload
  'user:registered': User
  'user:deleted': User
  // Other
  'data:update': Payload
};

export default defineNuxtPlugin(() => {
  // Then we just inform mitt about our event types
  const emitter = mitt<ApplicationEvents>()

  return {
    provide: {
      event: emitter.emit, // Will emit an event
      listen: emitter.on // Will register a listener for an event
    }
  }
})

// Based on
// https://dev.to/israelortuno/event-bus-pattern-in-nuxt-3-with-full-typescript-support-1okp
// User like this:
// const { $event, $listen} = useNuxtApp()
// $event('user:registered', {name:'Bjorn'})
// $listen('data:update', () => {
//   console.log('A user was registered!', user)
//   // do something here...
//   // An interesting example for this use case could be to 
//   // just register an event or a conversion in your 
//   // favourite analytics tool like Google Analytics, Fathom...
// })
