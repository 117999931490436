import revive_payload_client_4sVQNw7RlN from "/srv/node/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/srv/node/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/srv/node/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/srv/node/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_A0OWXRrUgq from "/srv/node/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/srv/node/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/srv/node/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_AOuQ1DYzjk from "/srv/node/app/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/srv/node/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/srv/node/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import api_GFfDXud5Cr from "/srv/node/app/plugins/api.ts";
import event_bus_S6Iq1iGe5d from "/srv/node/app/plugins/event-bus.ts";
import externals_Amb2lWhsAG from "/srv/node/app/plugins/externals.ts";
import snackbar_ozQSg3RwSv from "/srv/node/app/plugins/snackbar.js";
import vuetify_7h9QAQEssH from "/srv/node/app/plugins/vuetify.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_AOuQ1DYzjk,
  chunk_reload_client_UciE0i6zes,
  check_outdated_build_client_8vK7RkfGxZ,
  api_GFfDXud5Cr,
  event_bus_S6Iq1iGe5d,
  externals_Amb2lWhsAG,
  snackbar_ozQSg3RwSv,
  vuetify_7h9QAQEssH
]