import { default as about3Z6JBblbVwMeta } from "/srv/node/app/pages/about.vue?macro=true";
import { default as alertsBaXFeeVw2IMeta } from "/srv/node/app/pages/alerts.vue?macro=true";
import { default as buttonsOIwh7GWSA2Meta } from "/srv/node/app/pages/buttons.vue?macro=true";
import { default as cardsVhJYJ3uLRkMeta } from "/srv/node/app/pages/cards.vue?macro=true";
import { default as carouselLd8EfmRXISMeta } from "/srv/node/app/pages/carousel.vue?macro=true";
import { default as _91id_93eydODPDvICMeta } from "/srv/node/app/pages/datasources/[id].vue?macro=true";
import { default as _91id_93PTtRi3EU4rMeta } from "/srv/node/app/pages/documents/[id].vue?macro=true";
import { default as indexxBgaXt0NocMeta } from "/srv/node/app/pages/documents/index.vue?macro=true";
import { default as indexNHoxYN1tIeMeta } from "/srv/node/app/pages/hardware/config/index.vue?macro=true";
import { default as index3XKKngsocQMeta } from "/srv/node/app/pages/hardware/index.vue?macro=true";
import { default as indexspapEOacWuMeta } from "/srv/node/app/pages/index.vue?macro=true";
import { default as loginqyaFPxDnoAMeta } from "/srv/node/app/pages/login.vue?macro=true";
import { default as _91id_93Hee5QPbmr0Meta } from "/srv/node/app/pages/rentals/[id].vue?macro=true";
import { default as _91id_93KQSHl7d4xuMeta } from "/srv/node/app/pages/rentals/device/[id].vue?macro=true";
import { default as indexIMad8QTqWEMeta } from "/srv/node/app/pages/rentals/index.vue?macro=true";
import { default as _91id_93tixyEHLkP2Meta } from "/srv/node/app/pages/reports/filling/calculated/[id].vue?macro=true";
import { default as _91id_93yB6QEjF6NYMeta } from "/srv/node/app/pages/reports/filling/manual/[id].vue?macro=true";
import { default as indexqde7GlOrJpMeta } from "/srv/node/app/pages/reports/index.vue?macro=true";
import { default as tablesWosRoHqB13Meta } from "/srv/node/app/pages/tables.vue?macro=true";
import { default as _91id_93lwtmybmSSWMeta } from "/srv/node/app/pages/tickets/[id].vue?macro=true";
import { default as indexKBixUUT4euMeta } from "/srv/node/app/pages/tickets/index.vue?macro=true";
import { default as _91id_93bfOiumylYKMeta } from "/srv/node/app/pages/uns/[id].vue?macro=true";
import { default as indexKZTemiyjBzMeta } from "/srv/node/app/pages/uns/index.vue?macro=true";
import { default as _91id_933FKau1EJMUMeta } from "/srv/node/app/pages/uns/mqtt/[id].vue?macro=true";
import { default as indexzBaW4stVaOMeta } from "/srv/node/app/pages/uns/mqtt/index.vue?macro=true";
export default [
  {
    name: about3Z6JBblbVwMeta?.name ?? "about",
    path: about3Z6JBblbVwMeta?.path ?? "/about",
    meta: about3Z6JBblbVwMeta || {},
    alias: about3Z6JBblbVwMeta?.alias || [],
    redirect: about3Z6JBblbVwMeta?.redirect || undefined,
    component: () => import("/srv/node/app/pages/about.vue").then(m => m.default || m)
  },
  {
    name: alertsBaXFeeVw2IMeta?.name ?? "alerts",
    path: alertsBaXFeeVw2IMeta?.path ?? "/alerts",
    meta: alertsBaXFeeVw2IMeta || {},
    alias: alertsBaXFeeVw2IMeta?.alias || [],
    redirect: alertsBaXFeeVw2IMeta?.redirect || undefined,
    component: () => import("/srv/node/app/pages/alerts.vue").then(m => m.default || m)
  },
  {
    name: buttonsOIwh7GWSA2Meta?.name ?? "buttons",
    path: buttonsOIwh7GWSA2Meta?.path ?? "/buttons",
    meta: buttonsOIwh7GWSA2Meta || {},
    alias: buttonsOIwh7GWSA2Meta?.alias || [],
    redirect: buttonsOIwh7GWSA2Meta?.redirect || undefined,
    component: () => import("/srv/node/app/pages/buttons.vue").then(m => m.default || m)
  },
  {
    name: cardsVhJYJ3uLRkMeta?.name ?? "cards",
    path: cardsVhJYJ3uLRkMeta?.path ?? "/cards",
    meta: cardsVhJYJ3uLRkMeta || {},
    alias: cardsVhJYJ3uLRkMeta?.alias || [],
    redirect: cardsVhJYJ3uLRkMeta?.redirect || undefined,
    component: () => import("/srv/node/app/pages/cards.vue").then(m => m.default || m)
  },
  {
    name: carouselLd8EfmRXISMeta?.name ?? "carousel",
    path: carouselLd8EfmRXISMeta?.path ?? "/carousel",
    meta: carouselLd8EfmRXISMeta || {},
    alias: carouselLd8EfmRXISMeta?.alias || [],
    redirect: carouselLd8EfmRXISMeta?.redirect || undefined,
    component: () => import("/srv/node/app/pages/carousel.vue").then(m => m.default || m)
  },
  {
    name: _91id_93eydODPDvICMeta?.name ?? "datasources-id",
    path: _91id_93eydODPDvICMeta?.path ?? "/datasources/:id()",
    meta: _91id_93eydODPDvICMeta || {},
    alias: _91id_93eydODPDvICMeta?.alias || [],
    redirect: _91id_93eydODPDvICMeta?.redirect || undefined,
    component: () => import("/srv/node/app/pages/datasources/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93PTtRi3EU4rMeta?.name ?? "documents-id",
    path: _91id_93PTtRi3EU4rMeta?.path ?? "/documents/:id()",
    meta: _91id_93PTtRi3EU4rMeta || {},
    alias: _91id_93PTtRi3EU4rMeta?.alias || [],
    redirect: _91id_93PTtRi3EU4rMeta?.redirect || undefined,
    component: () => import("/srv/node/app/pages/documents/[id].vue").then(m => m.default || m)
  },
  {
    name: indexxBgaXt0NocMeta?.name ?? "documents",
    path: indexxBgaXt0NocMeta?.path ?? "/documents",
    meta: indexxBgaXt0NocMeta || {},
    alias: indexxBgaXt0NocMeta?.alias || [],
    redirect: indexxBgaXt0NocMeta?.redirect || undefined,
    component: () => import("/srv/node/app/pages/documents/index.vue").then(m => m.default || m)
  },
  {
    name: indexNHoxYN1tIeMeta?.name ?? "hardware-config",
    path: indexNHoxYN1tIeMeta?.path ?? "/hardware/config",
    meta: indexNHoxYN1tIeMeta || {},
    alias: indexNHoxYN1tIeMeta?.alias || [],
    redirect: indexNHoxYN1tIeMeta?.redirect || undefined,
    component: () => import("/srv/node/app/pages/hardware/config/index.vue").then(m => m.default || m)
  },
  {
    name: index3XKKngsocQMeta?.name ?? "hardware",
    path: index3XKKngsocQMeta?.path ?? "/hardware",
    meta: index3XKKngsocQMeta || {},
    alias: index3XKKngsocQMeta?.alias || [],
    redirect: index3XKKngsocQMeta?.redirect || undefined,
    component: () => import("/srv/node/app/pages/hardware/index.vue").then(m => m.default || m)
  },
  {
    name: indexspapEOacWuMeta?.name ?? "index",
    path: indexspapEOacWuMeta?.path ?? "/",
    meta: indexspapEOacWuMeta || {},
    alias: indexspapEOacWuMeta?.alias || [],
    redirect: indexspapEOacWuMeta?.redirect || undefined,
    component: () => import("/srv/node/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginqyaFPxDnoAMeta?.name ?? "login",
    path: loginqyaFPxDnoAMeta?.path ?? "/login",
    meta: loginqyaFPxDnoAMeta || {},
    alias: loginqyaFPxDnoAMeta?.alias || [],
    redirect: loginqyaFPxDnoAMeta?.redirect || undefined,
    component: () => import("/srv/node/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Hee5QPbmr0Meta?.name ?? "rentals-id",
    path: _91id_93Hee5QPbmr0Meta?.path ?? "/rentals/:id()",
    meta: _91id_93Hee5QPbmr0Meta || {},
    alias: _91id_93Hee5QPbmr0Meta?.alias || [],
    redirect: _91id_93Hee5QPbmr0Meta?.redirect || undefined,
    component: () => import("/srv/node/app/pages/rentals/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93KQSHl7d4xuMeta?.name ?? "rentals-device-id",
    path: _91id_93KQSHl7d4xuMeta?.path ?? "/rentals/device/:id()",
    meta: _91id_93KQSHl7d4xuMeta || {},
    alias: _91id_93KQSHl7d4xuMeta?.alias || [],
    redirect: _91id_93KQSHl7d4xuMeta?.redirect || undefined,
    component: () => import("/srv/node/app/pages/rentals/device/[id].vue").then(m => m.default || m)
  },
  {
    name: indexIMad8QTqWEMeta?.name ?? "rentals",
    path: indexIMad8QTqWEMeta?.path ?? "/rentals",
    meta: indexIMad8QTqWEMeta || {},
    alias: indexIMad8QTqWEMeta?.alias || [],
    redirect: indexIMad8QTqWEMeta?.redirect || undefined,
    component: () => import("/srv/node/app/pages/rentals/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93tixyEHLkP2Meta?.name ?? "reports-filling-calculated-id",
    path: _91id_93tixyEHLkP2Meta?.path ?? "/reports/filling/calculated/:id()",
    meta: _91id_93tixyEHLkP2Meta || {},
    alias: _91id_93tixyEHLkP2Meta?.alias || [],
    redirect: _91id_93tixyEHLkP2Meta?.redirect || undefined,
    component: () => import("/srv/node/app/pages/reports/filling/calculated/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93yB6QEjF6NYMeta?.name ?? "reports-filling-manual-id",
    path: _91id_93yB6QEjF6NYMeta?.path ?? "/reports/filling/manual/:id()",
    meta: _91id_93yB6QEjF6NYMeta || {},
    alias: _91id_93yB6QEjF6NYMeta?.alias || [],
    redirect: _91id_93yB6QEjF6NYMeta?.redirect || undefined,
    component: () => import("/srv/node/app/pages/reports/filling/manual/[id].vue").then(m => m.default || m)
  },
  {
    name: indexqde7GlOrJpMeta?.name ?? "reports",
    path: indexqde7GlOrJpMeta?.path ?? "/reports",
    meta: indexqde7GlOrJpMeta || {},
    alias: indexqde7GlOrJpMeta?.alias || [],
    redirect: indexqde7GlOrJpMeta?.redirect || undefined,
    component: () => import("/srv/node/app/pages/reports/index.vue").then(m => m.default || m)
  },
  {
    name: tablesWosRoHqB13Meta?.name ?? "tables",
    path: tablesWosRoHqB13Meta?.path ?? "/tables",
    meta: tablesWosRoHqB13Meta || {},
    alias: tablesWosRoHqB13Meta?.alias || [],
    redirect: tablesWosRoHqB13Meta?.redirect || undefined,
    component: () => import("/srv/node/app/pages/tables.vue").then(m => m.default || m)
  },
  {
    name: _91id_93lwtmybmSSWMeta?.name ?? "tickets-id",
    path: _91id_93lwtmybmSSWMeta?.path ?? "/tickets/:id()",
    meta: _91id_93lwtmybmSSWMeta || {},
    alias: _91id_93lwtmybmSSWMeta?.alias || [],
    redirect: _91id_93lwtmybmSSWMeta?.redirect || undefined,
    component: () => import("/srv/node/app/pages/tickets/[id].vue").then(m => m.default || m)
  },
  {
    name: indexKBixUUT4euMeta?.name ?? "tickets",
    path: indexKBixUUT4euMeta?.path ?? "/tickets",
    meta: indexKBixUUT4euMeta || {},
    alias: indexKBixUUT4euMeta?.alias || [],
    redirect: indexKBixUUT4euMeta?.redirect || undefined,
    component: () => import("/srv/node/app/pages/tickets/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93bfOiumylYKMeta?.name ?? "uns-id",
    path: _91id_93bfOiumylYKMeta?.path ?? "/uns/:id()",
    meta: _91id_93bfOiumylYKMeta || {},
    alias: _91id_93bfOiumylYKMeta?.alias || [],
    redirect: _91id_93bfOiumylYKMeta?.redirect || undefined,
    component: () => import("/srv/node/app/pages/uns/[id].vue").then(m => m.default || m)
  },
  {
    name: indexKZTemiyjBzMeta?.name ?? "uns",
    path: indexKZTemiyjBzMeta?.path ?? "/uns",
    meta: indexKZTemiyjBzMeta || {},
    alias: indexKZTemiyjBzMeta?.alias || [],
    redirect: indexKZTemiyjBzMeta?.redirect || undefined,
    component: () => import("/srv/node/app/pages/uns/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_933FKau1EJMUMeta?.name ?? "uns-mqtt-id",
    path: _91id_933FKau1EJMUMeta?.path ?? "/uns/mqtt/:id()",
    meta: _91id_933FKau1EJMUMeta || {},
    alias: _91id_933FKau1EJMUMeta?.alias || [],
    redirect: _91id_933FKau1EJMUMeta?.redirect || undefined,
    component: () => import("/srv/node/app/pages/uns/mqtt/[id].vue").then(m => m.default || m)
  },
  {
    name: indexzBaW4stVaOMeta?.name ?? "uns-mqtt",
    path: indexzBaW4stVaOMeta?.path ?? "/uns/mqtt",
    meta: indexzBaW4stVaOMeta || {},
    alias: indexzBaW4stVaOMeta?.alias || [],
    redirect: indexzBaW4stVaOMeta?.redirect || undefined,
    component: () => import("/srv/node/app/pages/uns/mqtt/index.vue").then(m => m.default || m)
  }
]