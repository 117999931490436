<!-- components/GlobalSnackbar.vue --> 
<script setup>
  const { $snackbar } = useNuxtApp();
</script>

<template>
  <v-snackbar top v-model="$snackbar.state.show" :color="$snackbar.state.color" :timeout="$snackbar.state.timeout">
    {{ $snackbar.state.message }}
    <template v-slot:action="{ attrs }">
      <v-btn color="white" text v-bind="attrs" @click="$snackbar.close">
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>