// plugins/snackbar.js
export default defineNuxtPlugin((nuxtApp) => {
    const snackbarState = reactive({
        show: false,
        message: '',
        color: 'error',
        timeout: 6000
    });

    function openSnackbar(message, color = 'error', timeout = 6000) {
        snackbarState.message = message;
        snackbarState.color = color;
        snackbarState.timeout = timeout;
        snackbarState.show = true;
    }

    function closeSnackbar() {
        snackbarState.show = false;
    }

    nuxtApp.provide('snackbar', {
        state: snackbarState,
        open: openSnackbar,
        close: closeSnackbar
    });
});
