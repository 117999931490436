<script setup lang="ts">
  const title = ref('Admin Portal')
  const description = ref('Administrate Your Resources')
  const { data } = useSession();
  const signEmail = data?.value?.user?.email || 'notsignedin'
  // This will be reactive when you change title/description above
  useHead({
    title,
    meta: [{
      name: 'description',
      content: description
    }],
    // script: [
    //   {
    //     src: `https://ctrl-as.odoo.com/im_livechat/loader/3`,
    //     type: 'text/javascript',
    //   },
    //   {
    //     src: `https://ctrl-as.odoo.com/im_livechat/assets_embed.js`,
    //     type: 'text/javascript',
    //   },
    // ]
})
</script>
<template>
  <div>
    <NuxtLayout>
      <ErrorSnackbar />
      <NuxtLoadingIndicator color="#ebc334"/>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>
